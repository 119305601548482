<template>
  <div class="tw-relative">
    <img
      :key="validSlide.uid + '-image-desktop'"
      aria-hidden="true"
      class="
        tw-hidden tw-h-full tw-w-full tw-object-cover
        sm:tw-block
        slide-img-dsk
      "
      :src="validSlide.image_url"
    />
    <img
      :key="validSlide.uid + '-image-mobile'"
      aria-hidden="true"
      class="tw-w-full tw-object-cover sm:tw-hidden slide-img-mbl"
      :src="validSlide.mobile_image_url"
    />
    <div class="slide-ot-detail">
      <div class="tw-mx-auto tw-max-w-7xl">
        <div
          class="
            tw-mx-auto
            tw-mt-6
            tw-max-w-lg
            tw-text-center
            tw-text-lg
            tw-text-white
            md:tw-max-w-3xl
          "
          v-html="validSlide.body"
        >
          <!-- {{ validSlide.body }} -->
        </div>
        <div
          class="
            tw-mx-auto tw-mt-10 tw-max-w-sm
            sm:tw-flex sm:tw-max-w-none sm:tw-justify-center
          "
        >
          <div
            class="
              tw-space-y-4
              sm:tw-mx-auto
              sm:tw-inline-grid
              sm:tw-grid-cols-1
              sm:tw-gap-5
              sm:tw-space-y-0
            "
          >
            <div v-if="validSlide.has_cta">
              <div v-if="validSlide.is_btn_cta">
                <v-btn
                  v-if="validSlide.cta_link_type == 'external'"
                  rounded
                  class="
                    tw-w-full
                    tw-bg-transparent
                    tw-px-8
                    tw-font-bold
                    tw-normal-case
                    tw-shadow-md
                    focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300
                    sm:tw-w-auto
                  "
                  color="black"
                  dark
                  :href="validSlide.cta_link"
                  target="_blank"
                  >{{ validSlide.cta_label }}</v-btn
                >
                <v-btn
                  v-else
                  rounded
                  class="
                    tw-w-full
                    tw-bg-transparent
                    tw-px-8
                    tw-font-bold
                    tw-normal-case
                    tw-shadow-md
                    focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300
                    sm:tw-w-auto
                  "
                  color="black"
                  dark
                  :to="resolveInternalLink(validSlide.cta_link)"
                  >{{ validSlide.cta_label }}</v-btn
                >
              </div>
              <div v-else>
                <a
                  v-if="validSlide.cta_link_type == 'external'"
                  :href="validSlide.cta_link"
                  target="_blank"
                  class="overlay_image_link"
                />
                <router-link
                  v-else
                  :to="resolveInternalLink(validSlide.cta_link)"
                  class="overlay_image_link"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validateSectionSliderSlide } from '@/models/RegistryLanding'
export default {
  props: {
    slug: {
      type: String,
      default: '',
    },
    slide: {
      type: Object,
      default: () => ({}),
    },
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    validSlide() {
      return validateSectionSliderSlide(this.slide)
    },
  },
  methods: {
    resolveInternalLink(key) {
      let params = { slug: this.slug }
      return (
        {
          register: {
            name: 'registry.register',
            params,
          },
          login: {
            name: 'registry.login',
            params,
          },
        }[key] || {
          name: 'registry.register',
          params,
        }
      )
    },
  },
}
</script>
<style>
</style>