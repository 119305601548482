import { RegistryServiceInstance } from '@/services/RegistryService'
import { formErrorsHandler } from '@/utils/formErrorsHandler'
import { RegistryObj } from '@/utils/Registry'

const resolveLocale = (registryLocal, isOnlyActiveForDefaultLang) => {
  if (isOnlyActiveForDefaultLang) {
    return registryLocal
  }
  try {
    const navigatorLocale = navigator.language.slice(0, 2).toLowerCase()
    if (['es', 'en'].includes(navigatorLocale)) {
      return navigatorLocale
    }
    return registryLocal
  } catch (error) {
    return registryLocal
  }
}

// state
const initState = () => {
  return {
    show: {
      current_language_key: 'es',
      isLoading: true,
      registry_status: 'open',
      simple_calendar_modal: false,
      data: RegistryObj(),
    },
    toAlert: {
      isActive: false,
    },
    toPath: {
      link: '',
      params:{},
    },
    toPathContact: {
      uuid: '',
    },
  }
}

// getters
const getters = {}

// actions
const actions = {
  index({ dispatch }) {
    const navigatorLocale = navigator.language.slice(0, 2).toLowerCase()
    const locale = ['es', 'en'].includes(navigatorLocale)
      ? navigatorLocale
      : 'en'
    dispatch('language/updateLocate', { locale }, { root: true })
    // commit('setShow', { isLoading: true })
    return RegistryServiceInstance.index()
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  show({ commit, dispatch }, { id }) {
    commit('setShow', { isLoading: true })
    return RegistryServiceInstance.show(id)
      .then(({ data }) => {
        const registryLanguageKey = data.data.language.name
        const isOnlyActiveForDefaultLang =
          data.data.is_only_active_for_default_lang

        const locale = resolveLocale(
          registryLanguageKey,
          isOnlyActiveForDefaultLang,
        )
        dispatch('language/updateLocate', { locale }, { root: true })
        commit('setShow', {
          data: data.data,
          isLoading: false,
          registry_status: 'open',
        })
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
}

// mutations
const mutations = {
  setShow(state, obj) {
    Object.keys(obj).forEach((k) => (state.show[k] = obj[k]))
  },
  setPath(state, obj) {
    Object.keys(obj).forEach((k) => (state.toPath[k] = obj[k]))
  },
  setPathContact(state, obj) {
    Object.keys(obj).forEach((k) => (state.toPathContact[k] = obj[k]))
  },
  setAlert(state, obj) {
    Object.keys(obj).forEach((k) => (state.toAlert[k] = obj[k]))
  },
}

export const Registry = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
