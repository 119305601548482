<template>
  <!-- <v-dialog
    v-model="open"
    persistent
    width="1024"
    scrollable
    :fullscreen="false"
    content-class="tw-relative tw-self-end tw-rounded-xl sm:tw-self-auto"
  > -->
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="1024px"
    content-class="tw-rounded-xl fill-height  lg:tw-max-h-[640px] tw-w-full"
  >
    <v-card class="fill-height tw-rounded-xl tw-border-0 tw-shadow-md">
      <v-container class="tw-p-0 tw-shadow-md tw-z-10" fluid>
        <div
          class="tw-relative tw-grid tw-grid-cols-12 tw-items-center tw-rounded-t-xl tw-bg-primary-500 tw-pl-5"
        >
          <div class="tw-col-span-11 tw-flex tw-items-end">
            <v-tabs
              v-model="tab"
              dark
              background-color="tw-bg-primary-500"
              show-arrows
            >
              <v-tabs-slider color="primary lighten-3" />
              <v-tab
                v-for="item in items"
                :key="item.name"
                :href="`#${item.name}`"
                class="tw-py-3 tw-px-6 tw-text-xs"
              >
                {{ item.label }}
              </v-tab>
            </v-tabs>
          </div>
          <div class="tw-flex tw-h-full tw-items-center tw-justify-end tw-px-2">
            <v-fab-transition>
              <v-btn
                fab
                x-small
                class="focus:tw-outline-none focus:tw-ring"
                @click="open = false"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </v-fab-transition>
          </div>
        </div>
      </v-container>
      <!-- <v-card-text class="tw-p-0" style="max-height: 700px"> -->
      <v-card-text class="fill-height tw-border-0 tw-p-0">
        <v-responsive class="fill-height tw-py-0">
          <v-tabs-items v-model="tab" class="fill-height">
            <v-tab-item
              :key="TabKeys.profile"
              class="fill-height"
              :value="TabKeys.profile"
            >
              <UserProfile />
            </v-tab-item>
            <v-tab-item
              :key="TabKeys.contacts"
              class="fill-height"
              :value="TabKeys.contacts"
            >
              <UserProfileCards />
            </v-tab-item>
            <v-tab-item
              :key="TabKeys.saved"
              class="fill-height"
              :value="TabKeys.saved"
            >
              <UserBookmarks />
            </v-tab-item>
            <v-tab-item
              :key="TabKeys.points"
              class="fill-height"
              :value="TabKeys.points"
            >
              <UserProfilePoints />
              <!-- <v-card flat class="tw-aspect-h-9 tw-aspect-w-16">
                <v-card-text>Bonos</v-card-text>
              </v-card> -->
            </v-tab-item>
            <v-tab-item
              :key="TabKeys.matchmaking"
              class="fill-height"
              :value="TabKeys.matchmaking"
            >
              <UserMatchmaking />
            </v-tab-item>
            <v-tab-item
              :key="TabKeys.preferences"
              class="fill-height"
              :value="TabKeys.preferences"
            >
              <UserPreferencesTab />
            </v-tab-item>
            <v-tab-item
              :key="TabKeys.credential"
              class="fill-height"
              :value="TabKeys.credential"
            >
              <UserCredential />
            </v-tab-item>
          </v-tabs-items>
        </v-responsive>
      </v-card-text>

      <v-divider v-if="false" />
      <v-card-actions v-if="false">
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          rounded
          class="tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-white"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary darken-1"
          rounded
          type="submit"
          class="text--white text--lighten-4 tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-300"
        >
          Guardar
        </v-btn>
      </v-card-actions>
      <!-- <div
        class="
          tw-absolute
          tw-pointer-events-none
          tw-inset-0
          tw-flex
          tw-justify-end
          tw-p-4
        "
      >
        <v-btn
          fab
          small
          class="tw-pointer-events-auto focus:tw-outline-none focus:tw-ring"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </div> -->
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { TabKeys } from '@/enums/TabKeys'
// const labels = [
//   { tab: 'Perfil' },
//   { tab: 'Tarjetas de presentación' },
//   { tab: 'Favoritos' },
//   { tab: 'Bonificaciones' },
// ]
import UserProfileCards from './UserProfileCards.vue'
import UserProfile from './UserProfile.vue'
import UserBookmarks from './UserBookmarks.vue'
import UserProfilePoints from './UserProfilePoints.vue'
import { mapFields } from '@/helpers'
import UserPreferencesTab from './UserPreferencesTab.vue'
import UserCredential from './UserCredential.vue'
import { CredentialHydrate } from '@/models/Credential'
import UserMatchmaking from './UserMatchmaking.vue'
export default {
  components: {
    UserProfile,
    UserProfileCards,
    UserBookmarks,
    UserProfilePoints,
    UserPreferencesTab,
    UserCredential,
    UserMatchmaking,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    TabKeys,
    configCrd: {},
  }),
  head() {
    return {
      style: [
        {
            type: 'text/css',
            inner: this.configCrd.custom_css,
        },
      ],
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user,
      registry: (state) => state.event.constants.registry,
    }),
    ...mapGetters({
      config: ['event/eventRegistryConfig'],
    }),
    items() {
      return (
        Object.keys(TabKeys)
          .filter((row) => {
            if (TabKeys.points == row) {
              return this.config.has_leaderboard
            }
            if (TabKeys.credential == row) {
              return this.registry.is_hybrid
            }
            if (TabKeys.matchmaking == row) {
              return false
            }
            return true
          })
          // .reduce((acc, curr) => {
          //   if ([TabKeys.points, TabKeys.credential].includes(curr)) {
          //     if (this.config.has_leaderboard) {
          //       return [...acc, curr]
          //     }
          //     if (this.registry.is_hybrid) {
          //       return [...acc, curr]
          //     }
          //     return acc
          //   }
          //   return [...acc, curr]
          // }, [])
          .map((row) => ({
            name: row,
            label: this.$i18n.t(`pages.event.profile.labels.${row}`),
          }))
      )
    },
    ...mapFields({
      fields: ['tab'],
      base: ['event', 'profile'],
      mutation: 'event/setProfile',
    }),
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    
    // labels,
  },
  mounted() {
      this.loadConfigCredential()
      this.$emit('updateHead')
    },
  methods: {
    loadConfigCredential() {
      this.configCrd === CredentialHydrate(
          this.registry.credential,
          this.registry.forms,
        ) 
    // console.log('registry event',this.config)
    },  
  },
}
</script>

<style>
</style>
