<template>
  <renderless-layout>
    <v-container fluid class="tw-p-0">
      <StickyHeader :nav="nav">
        <template #actions>
          <div class="tw-flex tw-items-center tw-space-x-4">
            <v-select
              v-model="current_config_language_key"
              :items="languages"
              :label="$t('admin.app.lang_version')"
              outlined
              dark
              class="tw-w-48"
              color="white"
              rounded
              hide-details=""
              dense
            />
            <AppBtn
              icon="g-next"
              g-next="Eye"
              :to="goToPreview"
              color="primary-dark"
            >
              {{ $t('app.preview') }}
            </AppBtn>
            <AppBtn icon="check" color="white" @click="updateEvent">
              {{ $t('app.save') }}
            </AppBtn>
          </div>
        </template>
      </StickyHeader>
      <v-row class="tw-m-0 tw-p-0">
        <v-col cols="12" class="mx-auto tw-container tw-max-w-7xl">
          <v-container fluid class="tw-space-y-6">
            <v-row class="">
              <v-col cols="12" md="4" class="px-5 pt-5 mx-auto">
                <FormDescription>
                  <template #title>
                    {{ $t('admin.events.landings_title') }}
                  </template>
                  <template #copy>
                    {{ $t('admin.events.landings_description') }}
                  </template>
                </FormDescription>
              </v-col>

              <v-col cols="12" md="8" class="mx-auto pa-5">
                <v-card class="mx-auto tw-rounded-3xl tw-shadow-md">
                  <v-container class="pa-5">
                    <v-row>
                      <v-col cols="12" sm="12" class="pb-0">
                        <label class="tw-block tw-pb-2 tw-text-gray-600">
                          {{ $t('admin.events.ui_primary_color') }}
                        </label>
                        <div>
                          <color-input v-model="ui_color" />
                        </div>
                      </v-col>

                      <v-col cols="12" sm="12" class="pb-0">
                        <label class="tw-block tw-pb-2 tw-text-gray-600">
                          {{ $t('admin.events.ui_contrast_color') }}
                        </label>
                        <div>
                          <color-input v-model="ui_contrast" />
                        </div>
                      </v-col>

                      <v-col cols="12" sm="6" class="tw-pb-0">
                        <label class="tw-block tw-pb-2 tw-text-gray-600">
                          {{ $t('admin.events.landing_logo') }}
                        </label>
                        <DropzonePreview
                          v-model="logo_url_sm"
                          :width="200"
                          :bypass="'no-check'"
                          :height="50"
                          media-group="register"
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <label class="tw-block tw-pb-2 tw-text-gray-600">
                          {{ $t('admin.events.landing_highlight_logo') }}
                        </label>
                        <DropzonePreview
                          v-model="logo_url_lg"
                          :width="300"
                          :bypass="'no-check'"
                          :height="150"
                          media-group="register"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
            <v-card
              flat
              color="transparent"
              class="tw-mb-6 tw-overflow-hidden tw-rounded-3xl tw-border-transparent tw-bg-transparent tw-shadow-md"
            >
              <v-container
                class="tw-divide-y tw-divide-primary-200 tw-bg-gradient-to-r tw-from-primary-900 tw-to-primary-500 tw-p-0"
                fluid
              >
                <div class="tw-px-4 tw-py-5 sm:tw-px-6">
                  <h2
                    class="tw-title-font tw-mb-2 tw-text-xl tw-font-medium tw-text-gray-50 sm:tw-text-2xl"
                  >
                    {{ $t('admin.events.landing_config_title') }}
                  </h2>
                  <p class="tw-mt-1 tw-max-w-2xl tw-text-sm tw-text-gray-100">
                    {{ $t('admin.events.landing_config_title_description') }}
                  </p>
                </div>
                <div
                  class="tw-flex tw-justify-between tw-px-4 tw-py-5 sm:tw-px-6"
                >
                  <div>
                    <AppBtn
                      color="white"
                      type="button"
                      @click="css_modal_toggle = true"
                    >
                      {{ $t('admin.events.custom_css') }}
                    </AppBtn>
                  </div>
                  <div class="sm:tw-flex sm:tw-space-x-3">
                    <v-switch
                      v-model="has_custom_landing"
                      dark
                      inset
                      class="tw-mt-0"
                      hide-details=""
                    >
                      <template #label>
                        <span class="tw-whitespace-nowrap">
                          {{ $t('admin.events.custom_landing') }}
                        </span>
                      </template>
                    </v-switch>
                    <AppBtn
                      color="primary-dark"
                      icon="code"
                      type="button"
                      :disabled="!has_custom_landing"
                      @click="html_modal_toggle = true"
                    >
                      {{ $t('admin.events.custom_html') }}
                    </AppBtn>
                  </div>
                </div>
              </v-container>
            </v-card>
            <v-row class="tw-relative">
              <transition name="fade-transition" mode="out-in">
                <div
                  v-if="has_custom_landing"
                  class="tw-absolute tw-inset-5 tw-z-5 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-40 tw-backdrop-blur-3xl tw-backdrop-saturate-150 tw-backdrop-filter lg:tw-rounded-3xl"
                >
                  <div
                    class="tw-mx-auto tw-max-w-3xl tw-px-4 tw-py-16 tw-text-center sm:tw-px-6 sm:tw-py-24 lg:tw-px-8 lg:tw-py-48"
                  >
                    {{ $t('admin.events.area_disabled') }}
                  </div>
                </div>
              </transition>
              <v-col cols="12" md="4" class="px-5 pt-5 mx-auto">
                <v-card class="tw-overflow-hidden tw-rounded-3xl tw-shadow">
                  <v-container class="tw-p-0">
                    <ul class="tw-pl-0">
                      <li>
                        <v-img :src="preview_hero_url" />
                      </li>
                    </ul>
                    <draggable
                      v-model="sections"
                      :move="onMove"
                      :animation="200"
                      class="tw-border-t tw-border-b tw-border-solid tw-border-gray-200"
                    >
                      <transition-group
                        tag="ul"
                        type="transition"
                        :name="'flip-list'"
                        class="tw-divide-y tw-divide-gray-200 tw-pl-0"
                      >
                        <li v-for="section in sections" :key="section.name">
                          <v-img
                            :src="sectionImgs[section.name]"
                            class="tw-cursor-move"
                          />
                        </li>
                      </transition-group>
                    </draggable>
                    <ul class="tw-pl-0">
                      <li>
                        <v-img :src="preview_footer_url" />
                      </li>
                    </ul>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="12" md="8" class="px-5 pt-5 mx-auto">
                <v-card class="tw-overflow-hidden tw-rounded-3xl tw-shadow">
                  <v-container class="tw-p-0">
                    <!--
                      In reference to full refactor to just utilizing sections
                    Issue ref: https://github.com/BizzDome/bizzdome-frontend/issues/15
                    -->
                    <ul
                      v-if="false"
                      class="tw-divide-y tw-divide-gray-200 tw-pl-0"
                    >
                      <li>
                        <div class="tw-block hover:tw-bg-gray-50">
                          <div
                            class="tw-flex tw-items-center tw-px-4 tw-py-4 sm:tw-px-6"
                          >
                            <div
                              class="tw-min-w-0 tw-flex-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between"
                            >
                              <div class="tw-truncate">
                                <div class="tw-flex tw-text-sm lg:tw-text-lg">
                                  <p
                                    class="tw-mb-0 tw-truncate tw-font-medium tw-text-gray-600"
                                  >
                                    {{
                                      $t(
                                        'admin.events.items.sections.main_banner',
                                      )
                                    }}
                                  </p>
                                </div>
                              </div>
                              <div
                                class="tw-mt-4 tw-flex-shrink-0 sm:tw-mt-0 sm:tw-ml-5"
                              >
                                <div
                                  class="tw-flex tw-items-center tw-space-x-2"
                                >
                                  <AppBtn
                                    color="primary"
                                    icon="pencil_edit"
                                    @click="hero_modal = true"
                                  >
                                    {{ $t('app.edit') }}
                                  </AppBtn>
                                </div>
                              </div>
                            </div>
                            <div class="tw-ml-5 tw-flex-shrink-0">
                              <div class="tw-h-5 tw-w-5" />
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <!-- tag="div"
                          class="tw-overflow-hidden tw-bg-white tw-shadow" -->
                    <draggable
                      v-model="sections"
                      :move="onMove"
                      ghost-class="moving-card"
                      :animation="200"
                      class="tw-border-t tw-border-b tw-border-solid tw-border-gray-200"
                    >
                      <transition-group
                        type="transition"
                        :name="'flip-list'"
                        tag="ul"
                        class="tw-divide-y tw-divide-gray-200 tw-pl-0"
                      >
                        <li v-for="section in sections" :key="section.name">
                          <div
                            class="tw-block tw-cursor-move hover:tw-bg-gray-50"
                          >
                            <div
                              class="tw-flex tw-items-center tw-px-4 tw-py-4 sm:tw-px-6"
                            >
                              <div
                                class="tw-min-w-0 tw-flex-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between"
                              >
                                <div class="tw-truncate">
                                  <div class="tw-flex tw-text-sm lg:tw-text-lg">
                                    <p
                                      class="tw-mb-0 tw-truncate tw-font-medium tw-text-gray-600"
                                    >
                                      {{
                                        $t(
                                          `admin.events.items.sections.${section.label}`,
                                        )
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  class="tw-mt-4 tw-flex-shrink-0 sm:tw-mt-0 sm:tw-ml-5"
                                >
                                  <div
                                    class="tw-flex tw-items-center tw-space-x-2"
                                  >
                                    <v-switch
                                      v-model="section.is_active"
                                      class="tw-mt-0"
                                      inset
                                      :label="$t('app.active')"
                                      color="primary"
                                      hide-details
                                      @change="sectionActive(section)"
                                    />
                                    <AppBtn
                                      color="primary"
                                      icon="pencil_edit"
                                      @click="sectionEdit(section)"
                                    >
                                      {{ $t('app.edit') }}
                                    </AppBtn>
                                  </div>
                                </div>
                              </div>
                              <div class="tw-ml-5 tw-flex-shrink-0">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  class="tw-h-5 tw-w-5 tw-text-gray-400"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M8 9l4-4 4 4m0 6l-4 4-4-4"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </li>
                      </transition-group>
                    </draggable>

                    <ul class="tw-divide-y tw-divide-gray-200 tw-pl-0">
                      <li>
                        <div class="tw-block hover:tw-bg-gray-50">
                          <div
                            class="tw-flex tw-items-center tw-px-4 tw-py-4 sm:tw-px-6"
                          >
                            <div
                              class="tw-min-w-0 tw-flex-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between"
                            >
                              <div class="tw-truncate">
                                <div class="tw-flex tw-text-sm lg:tw-text-lg">
                                  <p
                                    class="tw-mb-0 tw-truncate tw-font-medium tw-text-gray-600"
                                  >
                                    Pie de pagina
                                  </p>
                                </div>
                              </div>
                              <div
                                class="tw-mt-4 tw-flex-shrink-0 sm:tw-mt-0 sm:tw-ml-5"
                              >
                                <div
                                  class="tw-flex tw-items-center tw-space-x-2"
                                />
                              </div>
                            </div>
                            <div class="tw-ml-5 tw-flex-shrink-0">
                              <div class="tw-h-5 tw-w-5" />
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <RegistriesLandingsHeroModal
        v-if="hero_modal"
        v-model="hero_modal"
        :banner-sections="banner_sections"
        @updated-banner-sections="updatedBannerSections"
      />
      <RegistriesLandingsCountdownTimer
        v-if="section_countdown_timer"
        v-model="section_countdown_timer"
        :section="current_section"
        @updated-section="updateSection"
      />
      <RegistriesLandingsSocialMedia
        v-if="section_social_media"
        v-model="section_social_media"
        :section="current_section"
        @updated-section="updateSection"
      />
      <RegistriesLandingsMultiButtons
        v-if="section_multi_buttons"
        v-model="section_multi_buttons"
        :section="current_section"
        @updated-section="updateSection"
      />
      <RegistriesLandingsCalActModal
        v-if="section_activities_and_calendar_modal"
        v-model="section_activities_and_calendar_modal"
        :section="current_section"
        @updated-section="updateSection"
      />
      <RegistriesLandingsHeroCtaModal
        v-if="section_body_hero_cta_modal"
        v-model="section_body_hero_cta_modal"
        :section="current_section"
        @updated-section="updateSection"
      />
      <RegistriesLandingsLogosModal
        v-if="section_sponsor_logos_modal"
        v-model="section_sponsor_logos_modal"
        :section="current_section"
        @updated-section="updateSection"
      />
      <RegistriesLandingsSliderModal
        v-if="section_slider_modal"
        v-model="section_slider_modal"
        :section="current_section"
        @updated-section="updateSection"
      />
      <RegistriesLandingsImageRatioModal
        v-if="section_image_ratio_modal"
        v-model="section_image_ratio_modal"
        :section="current_section"
        @updated-section="updateSection"
      />
      <CSSEditorModal
        v-model="css_modal_toggle"
        :custom-css.sync="custom_css"
      />
      <HTMLEditorModal
        v-model="html_modal_toggle"
        :custom-html.sync="custom_landing_html"
      />
    </v-container>
  </renderless-layout>
</template>

<script>
import HTMLEditorModal from '@/components/HTMLEditorModal.vue'
import CSSEditorModal from '@/components/CSSEditorModal.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import StickyHeader from '@/components/StickyHeader.vue'
import { mapState, mapGetters } from 'vuex'
import { mapFields, mapNestedFields } from '@/helpers'
import DropzonePreview from '@/components/DropzonePreview'
import FormDescription from '@/components/FormDescription'
import draggable from 'vuedraggable'
import ColorInput from '@/components/FormInputs/ColorInput'
import RegistriesLandingsHeroModal from '@/components/RegistriesModals/RegistriesLandingsHeroModal.vue'
import RegistriesLandingsCalActModal from '@/components/RegistriesModals/RegistriesLandingsCalActModal.vue'
import RegistriesLandingsLogosModal from '@/components/RegistriesModals/RegistriesLandingsLogosModal.vue'
import RegistriesLandingsHeroCtaModal from '@/components/RegistriesModals/RegistriesLandingsHeroCtaModal.vue'
import RegistriesLandingsImageRatioModal from '@/components/RegistriesModals/RegistriesLandingsImageRatioModal.vue'
import RegistriesLandingsCountdownTimer from '@/components/RegistriesModals/RegistriesLandingsCountdownTimer.vue'
import RegistriesLandingsSocialMedia from '@/components/RegistriesModals/RegistriesLandingsSocialMedia.vue'
import RegistriesLandingsMultiButtons from '@/components/RegistriesModals/RegistriesLandingsMultiButtons.vue'

import { SectionTypeKeys } from '@/enums/SectionTypeKeys'
import RegistriesLandingsSliderModal from '@/components/RegistriesModals/RegistriesLandingsSliderModal.vue'
const sectionImgs = {
  [SectionTypeKeys.countdown_timer]: require('@/assets/landing-previews/s4-2.png'),
  [SectionTypeKeys.social_media]: require('@/assets/landing-previews/s4-2.png'),
  [SectionTypeKeys.multi_buttons]: require('@/assets/landing-previews/s4-2.png'),
  [SectionTypeKeys.activities]: require('@/assets/landing-previews/s4-2.png'),
  [SectionTypeKeys.cta]: require('@/assets/landing-previews/s2.png'),
  [SectionTypeKeys.sponsor]: require('@/assets/landing-previews/s5.png'),
  [SectionTypeKeys.image]: require('@/assets/landing-previews/s4-2.png'),
  [SectionTypeKeys.slider]: require('@/assets/landing-previews/s4-2.png'),
  [SectionTypeKeys.main_slider]: require('@/assets/landing-previews/s4-2.png'),
  [SectionTypeKeys.slider_two]: require('@/assets/landing-previews/s4-2.png'),
}
export default {
  components: {
    AppBtn,
    RegistriesLandingsHeroCtaModal,
    RegistriesLandingsHeroModal,
    RegistriesLandingsLogosModal,
    RegistriesLandingsImageRatioModal,
    ColorInput,
    FormDescription,
    StickyHeader,
    DropzonePreview,
    draggable,
    CSSEditorModal,
    HTMLEditorModal,
    RegistriesLandingsCalActModal,
    RegistriesLandingsSliderModal,
    RegistriesLandingsCountdownTimer,
    RegistriesLandingsSocialMedia,
    RegistriesLandingsMultiButtons,
  },
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
  },
  data: () => ({
    sectionImgs,
    html_modal_toggle: false,
    css_modal_toggle: false,
    hero_modal: false,
    section_activities_and_calendar_modal: false,
    section_countdown_timer: false,
    section_social_media: false,
    section_multi_buttons: false,
    section_body_hero_cta_modal: false,
    section_sponsor_logos_modal: false,
    section_slider_modal: false,
    section_image_ratio_modal: false,
    current_section: {},
  }),
  computed: {
    nav() {
      return [
        {
          name: this.$t('app.event'),
          route: { name: 'admin.registries.index' },
        },
        {
          name: this.show.label,
          route: {
            name: 'admin.registries.show.home',
            params: { id: this.show.id },
          },
        },
        {
          name: 'Landings',
          route: {},
        },
      ]
    },
    goToPreview() {
      return {
        name: 'registry.home',
        params: { slug: this.current_slug },
      }
    },
    ...mapFields({
      fields: ['current_config_language_key'],
      base: ['registries', 'landings', 'update', 'data'],
      mutation: 'registries/setLandingsUpdateWrapper',
    }),
    introHero: {
      get() {
        return {
          hero_image_url: this.hero_image_url,
          hero_title: this.hero_title,
          hero_description: this.hero_description,
        }
      },
      set(obj) {
        const { hero_image_url, hero_title, hero_description } = obj
        this.hero_image_url = hero_image_url
        this.hero_title = hero_title
        this.hero_description = hero_description
      },
    },
    ...mapNestedFields({
      fields: [
        'preview_hero_url',
        'preview_footer_url',
        'logo_url_lg',
        'logo_url_sm',
        'hero_image_url',
        'hero_title',
        'hero_description',
        'ui_color',
        'ui_contrast',
        'has_custom_landing',
        'custom_css',
        'custom_landing_html',
        'sections',
        'banner_sections',
      ],
      base: ['registries', 'landings', 'update', 'data'],
      mutation: 'registries/setLandingsUpdateForm',
      nested_key: 'current_config_language_key',
    }),

    ...mapGetters({
      languages: ['constants/languages'],
    }),
    ...mapState({
      show: (state) => state.registries.show.data,
    }),
    current_slug() {
      return this.show.slug
    },
  },

  beforeDestroy() {
    console.log('destroyed')
    this.$store.dispatch('registries/initLandingsUpdate')
  },
  methods: {
    updatedBannerSections(banner_sections) {
      this.banner_sections = banner_sections
    },
    updatedIntroHero(introHero) {
      this.introHero = introHero
    },
    sectionEdit(section) {
      this.current_section = section
      const look_up = {
        countdown_timer: (section) => {
          this.section_countdown_timer = true
          console.log(section.data)
        },
        social_media: (section) => {
          this.section_social_media = true
          console.log(section.data)
        },
        multi_buttons: (section) => {
          this.section_multi_buttons = true
          console.log(section.data)
        },
        activities_and_calendar: (section) => {
          this.section_activities_and_calendar_modal = true
          console.log(section.data)
        },
        body_hero_cta: (section) => {
          this.section_body_hero_cta_modal = true
          console.log(section.data)
        },
        sponsor_logos: (section) => {
          this.section_sponsor_logos_modal = true
          console.log(section.data)
        },
        image_ratio: (section) => {
          this.section_image_ratio_modal = true
          console.log(section.data)
        },
        image_ratio_two: (section) => {
          this.section_image_ratio_modal = true
          console.log(section.data)
        },
        [SectionTypeKeys.main_slider]: () => {
          this.section_slider_modal = true
          console.log(section.data)
        },
        [SectionTypeKeys.slider]: () => {
          this.section_slider_modal = true
          console.log(section.data)
        },
        [SectionTypeKeys.slider_two]: () => {
          this.section_slider_modal = true
          console.log(section.data)
        },
      }
      look_up[section.name](section)
    },
    sectionActive(section) {
      this.sections = this.sections.map((row) => {
        return row.name === section.name ? section : row
      })
    },
    updateSection(section) {
      this.sections = this.sections.map((row) => {
        return row.name === section.name ? section : row
      })
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element
      const draggedElement = draggedContext.element
      return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
    },
    updateEvent() {
      // console.log(this.current_slug)
      this.$store.dispatch('registries/updateLandings').then(() =>
        this.$store.dispatch('registries/show', {
          id: this.id,
          appLoading: true,
        }),
      )
    },
    previewLanding() {
      this.$router.push({
        name: 'registry.home',
        params: { slug: this.current_slug },
      })
      // this.$router.
    },
  },
}
</script>

<style></style>
