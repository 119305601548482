// const Admin = () => import(/* webpackChunkName: "admin" */ '@/views/admin');
import Home from '../views/Home.vue'
import CommonContainer from '@/components/CommonContainer'

// Registries
import RegistriesIndex from '@/views/Admin/Registries/RegistriesIndex'
import RegistriesCreate from '@/views/Admin/Registries/RegistriesCreate'
import RegistriesShowContainer from '@/views/Admin/Registries/RegistriesShowContainer'
import RegistriesShowHome from '@/views/Admin/Registries/RegistriesShowHome'
import RegistriesShowLandings from '@/views/Admin/Registries/RegistriesShowLandings'
import RegistriesShowLoginScreens from '@/views/Admin/Registries/RegistriesShowLoginScreens'
import RegistriesShowConfirmScreens from '@/views/Admin/Registries/RegistriesShowConfirmScreens'
import RegistriesShowEmails from '@/views/Admin/Registries/RegistriesShowEmails'
import RegistriesConfigsIndex from '@/views/Admin/Registries/RegistriesConfigsIndex'
import RegistriesShowRegisters from '@/views/Admin/Registries/RegistriesShowRegisters'
import RegistriesShowThankyous from '@/views/Admin/Registries/RegistriesShowThankyous'
import RegistriesParticipantsIndex from '@/views/Admin/Registries/RegistriesParticipantsIndex'
import RegistriesCalendarHome from '@/views/Admin/Registries/RegistriesCalendarHome'
import RegistriesAccessControlIndex from '@/views/Admin/Registries/RegistriesAccessControlIndex'
import RegistriesAccessControlCreate from '@/views/Admin/Registries/RegistriesAccessControlCreate'
import RegistriesAccessControlEdit from '@/views/Admin/Registries/RegistriesAccessControlEdit'
import RegistriesAccessControlContainer from '@/views/Admin/Registries/RegistriesAccessControlContainer'
// Donovann: Credential - Certificate Print
import RegistriesCredentialPrint from '@/views/Admin/Registries/RegistriesCredentialPrint'
import RegistriesCertificatePrint from '@/views/Admin/Registries/RegistriesCertificatePrint'
// Donovann
import RegistriesTicketIndex from '@/views/Admin/Registries/RegistriesTicketIndex'
import RegistriesCredentialsIndex from '@/views/Admin/Registries/RegistriesCredentialsIndex'
import RegistriesCertificatesIndex from '@/views/Admin/Registries/RegistriesCertificatesIndex'


// Legacy Config
import ConfigsContainer from '@/views/Admin/Configs/ConfigsContainer'
import ConfigsHome from '@/views/Admin/Configs/ConfigsHome'
import ConfigsIndex from '@/views/Admin/Configs/ConfigsIndex'
import ConfigsEventBroadcastsIndex from '@/views/Admin/Configs/ConfigsEventBroadcastsIndex'
import ConfigsEventBroadcastsCreate from '@/views/Admin/Configs/ConfigsEventBroadcastsCreate'
import ConfigsEventBroadcastsContainer from '@/views/Admin/Configs/ConfigsEventBroadcastsContainer'
import ConfigsEventBroadcastsEdit from '@/views/Admin/Configs/ConfigsEventBroadcastsEdit'
import ConfigsNavItemsIndex from '@/views/Admin/Configs/ConfigsNavItemsIndex'

export default [
  {
    path: '/admin',
    component: CommonContainer,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    children: [
      {
        path: '',
        name: 'admin.dashboard',
        component: Home,
      },
      {
        path: 'dashboard',
        name: 'admin.dashboard.rewrite',
        redirect: '/admin',
      },
      {
        path: 'configs',
        component: ConfigsContainer,
        children: [
          {
            path: '',
            name: 'admin.configs.home',
            component: ConfigsHome,
          },
          {
            path: 'general',
            name: 'admin.configs.index',
            component: ConfigsIndex,
          },
          {
            path: 'event-broadcasts',
            component: CommonContainer,
            children: [
              {
                path: 'rewrite',
                name: 'admin.configs.event-broadcasts.rewrite',
                redirect: {
                  name: 'admin.configs.event-broadcasts.index',
                },
              },
              {
                path: '',
                name: 'admin.configs.event-broadcasts.index',
                component: ConfigsEventBroadcastsIndex,
              },
              {
                path: 'create',
                name: 'admin.configs.event-broadcasts.create',
                component: ConfigsEventBroadcastsCreate,
              },
              {
                path: ':id',
                component: ConfigsEventBroadcastsContainer,
                props: true,
                children: [
                  {
                    path: '',
                    name: 'admin.configs.event-broadcasts.edit',
                    component: ConfigsEventBroadcastsEdit,
                    props: true,
                  },
                ],
              },
            ],
          },
          {
            path: 'leaderboards',
            component: CommonContainer,
            children: [
              {
                path: '',
                name: 'admin.configs.leaderboards.index',
                // component: ConfigsLeaderboardIndex,
                component: () =>
                  import(
                    /* webpackChunkName: "admin.configs.leaderboards.index" */
                    '@/views/Admin/Configs/ConfigsLeaderboardsIndex.vue'
                  ),
              },
              {
                path: 'create',
                component: CommonContainer,
                children: [
                  {
                    path: '',
                    name: 'admin.configs.leaderboards.create',
                    component: () =>
                      import(
                        /* webpackChunkName: "admin.configs.leaderboards.create" */
                        '@/views/Admin/Configs/ConfigsLeaderboardsCreate.vue'
                      ),
                    props: true,
                  },
                ],
              },
              {
                path: ':id',
                component: () =>
                  import(
                    /* webpackChunkName: "admin.configs.leaderboards.container" */
                    '@/views/Admin/Configs/ConfigsLeaderboardsContainer.vue'
                  ),
                props: true,
                children: [
                  {
                    path: '',
                    name: 'admin.configs.leaderboards.show',
                    component: () =>
                      import(
                        /* webpackChunkName: "admin.configs.leaderboards.show" */
                        '@/views/Admin/Configs/ConfigsLeaderboardsShow.vue'
                      ),
                    props: true,
                  },
                  {
                    path: 'edit',
                    name: 'admin.configs.leaderboards.edit',
                    component: () =>
                      import(
                        /* webpackChunkName: "admin.configs.leaderboards.edit" */
                        '@/views/Admin/Configs/ConfigsLeaderboardsEdit.vue'
                      ),
                    props: true,
                  },
                ],
              },
            ],
          },

          {
            path: 'additionals',
            // component: CommonContainer,
            component: () =>
              import(
                /* webpackChunkName: "admin-configs-limits-container" */
                '@/views/Admin/Configs/ConfigsLimitsContainer.vue'
              ),
            children: [
              {
                path: '',
                name: 'admin.configs.additionals',
                component: () =>
                  import(
                    /* webpackChunkName: "admin-configs-limits" */
                    '@/views/Admin/Configs/ConfigsLimits.vue'
                  ),
              },
            ],
          },
          {
            path: ':lang',
            component: CommonContainer,
            props: true,
            children: [
              {
                path: '',
                name: 'admin.configs.lang.rewrite',
                redirect: {
                  name: 'admin.configs.index',
                },
              },
              {
                path: 'navigation-items',
                name: 'admin.configs.lang.nav-items',
                component: ConfigsNavItemsIndex,
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: 'booths',
        component: CommonContainer,
        children: [
          {
            path: '',
            name: 'admin.booths.index',
            component: () =>
              import(
                /* webpackChunkName: "admin-booths-index" */
                '@/views/Admin/Booths/BoothsIndex.vue'
              ),
          },
          {
            path: 'create',
            component: () =>
              import(
                /* webpackChunkName: "admin-booths-create-container" */
                '@/views/Admin/Booths/BoothsCreateContainer.vue'
              ),
            children: [
              {
                path: '',
                name: 'admin.booths.create.one',
                // component: BoothsCreateStepOne,
                component: () =>
                  import(
                    /* webpackChunkName: "admin-booths-create-step-one" */
                    '@/views/Admin/Booths/BoothsCreateStepOne.vue'
                  ),
                props: true,
              },
              {
                path: 'step-two',
                name: 'admin.booths.create.two',
                // component: BoothsCreateStepTwo,
                component: () =>
                  import(
                    /* webpackChunkName: "admin-booths-create-step-two" */
                    '@/views/Admin/Booths/BoothsCreateStepTwo.vue'
                  ),
                props: true,
              },
            ],
          },
          {
            path: ':id',
            component: CommonContainer,
            props: true,
            children: [
              {
                path: '',
                name: 'admin.booths.edit',
                redirect: (to) => {
                  return {
                    name: 'admin.booths.edit.canvas',
                    params: { id: to.params.id },
                  }
                },
                props: true,
              },
              {
                path: 'edit-canvas',
                name: 'admin.booths.edit.canvas',
                component: () =>
                  import(
                    /* webpackChunkName: "admin.booths.show.edit-canvas" */
                    '@/views/Admin/Booths/BoothVisualCanvasEdit.vue'
                  ),
                props: true,
              },
            ],
          },

          // {
          //   path: "show/:id/edit-canvas",
          //   name: "show_booth_canvas",
          //   component: BoothsCreate,
          //   props: true,
          // },
          // {
          //   path: "show/:id/edit-canvas",
          //   name: "show_booth_canvas",
          //   component: BoothsCreate,
          //   props: true,
          // },
        ],
      },
      {
        path: 'users',
        component: CommonContainer,
        children: [
          {
            path: '',
            name: 'admin.users.index',
            // component: UsersIndex,
            component: () =>
              import(
                /* webpackChunkName: "admin-users-index" */
                '@/views/Admin/Users/UsersIndex.vue'
              ),
          },
          {
            path: 'rewrite',
            name: 'admin.users.rewrite',
            redirect: { name: 'admin.users.index' },
          },
          {
            path: 'create',
            component: CommonContainer,
            children: [
              {
                path: '',
                name: 'admin.users.create',
                // component: UsersCreate,
                component: () =>
                  import(
                    /* webpackChunkName: "admin-users-create" */
                    '@/views/Admin/Users/UsersCreate.vue'
                  ),
                props: true,
              },
            ],
          },
          {
            path: ':uuid',
            // component: UserContainer,
            component: () =>
              import(
                /* webpackChunkName: "admin-user-container" */
                '@/views/Admin/Users/UserContainer.vue'
              ),
            props: true,
            children: [
              {
                path: '',
                name: 'admin.users.show',
                component: () =>
                  import(
                    /* webpackChunkName: "admin.users.show" */
                    '@/views/Admin/Users/UsersShow.vue'
                  ),
                props: true,
              },
              {
                path: 'edit',
                name: 'admin.users.edit',
                // component: UsersEdit,
                component: () =>
                  import(
                    /* webpackChunkName: "admin-users-edit" */
                    '@/views/Admin/Users/UsersEdit.vue'
                  ),
                props: true,
              },
              {
                path: 'attendees',
                component: CommonContainer,
                props: true,
                children: [
                  {
                    path: 'create',
                    name: 'admin.users.attendees.create',
                    component: () =>
                      import(
                        /* webpackChunkName: "admin.users.attendees.create" */
                        '@/views/Admin/Users/UsersAttendeesCreate.vue'
                      ),
                    props: true,
                  },
                  {
                    path: ':attendee',
                    component: () =>
                      import(
                        /* webpackChunkName: "admin.users.attendees.container" */
                        '@/views/Admin/Users/UsersAttendeesContainer.vue'
                      ),
                    props: true,
                    children: [
                      {
                        path: '',
                        name: 'admin.users.attendees.show',
                        component: () =>
                          import(
                            /* webpackChunkName: "admin.users.attendees.show" */
                            '@/views/Admin/Users/UsersAttendeesShow.vue'
                          ),
                        props: true,
                      },
                      {
                        path: 'edit',
                        name: 'admin.users.attendees.edit',
                        // component: UsersEdit,
                        component: () =>
                          import(
                            /* webpackChunkName: "admin.users.attendees.edit" */
                            '@/views/Admin/Users/UsersAttendeesEdit.vue'
                          ),
                        props: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'content-library',
        component: CommonContainer,
        children: [
          {
            path: '',
            name: 'admin.content-library.index',
            component: () =>
              import(
                /* webpackChunkName: "admin.content-library.index" */
                '@/views/Admin/ContentLibrary/ContentLibraryIndex.vue'
              ),
          },
          {
            path: 'rewrite',
            name: 'admin.content-library.rewrite',
            redirect: { name: 'admin.content-library.index' },
          },
          {
            path: 'create',
            component: CommonContainer,
            children: [
              {
                path: '',
                name: 'admin.content-library.create',
                component: () =>
                  import(
                    /* webpackChunkName: "admin.content-library.create" */
                    '@/views/Admin/ContentLibrary/ContentLibraryCreate.vue'
                  ),

                props: true,
              },
            ],
          },

          {
            path: ':uuid',
            component: () =>
              import(
                /* webpackChunkName: "admin.content-library.show.container" */
                '@/views/Admin/ContentLibrary/ContentLibraryShowContainer.vue'
              ),
            props: true,
            children: [
              {
                path: '',
                name: 'admin.content-library.show.home',
                redirect: (to) => {
                  return {
                    name: 'admin.content-library.elements',
                    params: { uuid: to.params.uuid },
                  }
                },
                // redirect: ,
                props: true,
              },

              {
                path: 'elements',
                component: CommonContainer,
                props: true,
                children: [
                  {
                    path: '',
                    name: 'admin.content-library.elements',
                    // component: ContentLibraryElementsIndex,
                    component: () =>
                      import(
                        /* webpackChunkName: "admin.content-library.elements.index" */
                        '@/views/Admin/ContentLibrary/ContentLibraryElements/ContentLibraryElementsIndex.vue'
                      ),
                    props: true,
                  },
                  {
                    path: 'create',
                    component: CommonContainer,
                    children: [
                      {
                        path: '',
                        name: 'admin.content-library.elements.create',
                        // component: ContentLibraryElementsCreate,
                        component: () =>
                          import(
                            /* webpackChunkName: "admin.content-library.elements.create" */
                            '@/views/Admin/ContentLibrary/ContentLibraryElements/ContentLibraryElementsCreate.vue'
                          ),
                        props: true,
                      },
                    ],
                  },
                  {
                    path: ':element',
                    component: () =>
                      import(
                        /* webpackChunkName: "admin.content-library.elements.show.container" */
                        '@/views/Admin/ContentLibrary/ContentLibraryElements/ContentLibraryElementsShowContainer.vue'
                      ),
                    props: true,
                    children: [
                      {
                        path: '',
                        name: 'admin.content-library.elements.show',
                        component: () =>
                          import(
                            /* webpackChunkName: "admin.content-library.elements.show" */
                            '@/views/Admin/ContentLibrary/ContentLibraryElements/ContentLibraryElementsShow.vue'
                          ),
                        props: true,
                      },
                      {
                        path: 'edit',
                        name: 'admin.content-library.elements.edit',
                        component: () =>
                          import(
                            /* webpackChunkName: "admin.content-library.elements.edit" */
                            '@/views/Admin/ContentLibrary/ContentLibraryElements/ContentLibraryElementsEdit.vue'
                          ),
                        props: true,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'tags',
                component: CommonContainer,
                props: true,
                children: [
                  {
                    path: '',
                    name: 'admin.content-library.tags',
                    component: () =>
                      import(
                        /* webpackChunkName: "admin.content-library.tags" */
                        '@/views/Admin/ContentLibrary/ContentLibraryTags/ContentLibraryTagsIndex.vue'
                      ),
                    props: true,
                  },
                ],
              },
              {
                path: 'folders',
                component: CommonContainer,
                props: true,
                children: [
                  {
                    path: '',
                    name: 'admin.content-library.folders',
                    component: () =>
                      import(
                        /* webpackChunkName: "admin.content-library.folders" */
                        '@/views/Admin/ContentLibrary/ContentLibraryFolders/ContentLibraryFoldersIndex.vue'
                      ),
                    props: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'metrics',
        component: CommonContainer,
        children: [
          {
            path: '',
            name: 'admin.metrics.index',
            component: () =>
              import(
                /* webpackChunkName: "admin.metrics.index" */
                '@/views/Admin/Metrics/MetricsIndex.vue'
              ),
          },
          {
            path: 'print',
            name: 'admin.metrics.print',
            component: () =>
              import(
                /* webpackChunkName: "admin.metrics.print" */
                '@/views/Admin/Metrics/MetricsPrint.vue'
              ),
          },
        ],
      },
      {
        path: 'registries',
        component: CommonContainer,
        children: [
          {
            path: '',
            name: 'admin.registries.index',
            component: RegistriesIndex,
          },
          {
            path: 'create',
            component: CommonContainer,
            children: [
              {
                path: '',
                name: 'admin.registries.create',
                component: RegistriesCreate,
                props: true,
              },
            ],
          },
          {
            path: ':id',
            component: RegistriesShowContainer,
            props: true,
            children: [
              {
                path: '',
                name: 'admin.registries.show.home',
                component: RegistriesShowHome,
                props: true,
              },

              {
                path: 'access-control',
                component: CommonContainer,
                props: true,
                children: [
                  {
                    path: '',
                    name: 'admin.configs.access-control',
                    component: RegistriesAccessControlIndex,
                    props: true,
                  },
                  {
                    path: 'create',
                    name: 'admin.configs.access-control.create',
                    component: RegistriesAccessControlCreate,
                    props: true,
                  },
                  {
                    path: ':accessControl',
                    component: RegistriesAccessControlContainer,
                    props: true,
                    children: [
                      {
                        path: '',
                        name: 'admin.configs.access-control.edit',
                        component: RegistriesAccessControlEdit,
                        props: true,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'participants',
                component: CommonContainer,
                props: true,
                children: [
                  {
                    path: '',
                    name: 'admin.registries.participants',
                    component: RegistriesParticipantsIndex,
                    props: true,
                  },
                  {
                    path: 'create',
                    name: 'admin.registries.participants.create',
                    component: RegistriesAccessControlCreate,
                    props: true,
                  },
                  {
                    path: ':uuid',
                    component: RegistriesAccessControlContainer,
                    props: true,
                    children: [
                      {
                        path: '',
                        name: 'admin.registries.participants.edit',
                        component: RegistriesAccessControlEdit,
                        props: true,
                      },
                    ],
                  },
                  {
                    path: 'credentials',
                    name: 'admin.registries.participants.credentials',
                    component: RegistriesCredentialPrint,
                    props: true,
                  },
                  {
                    path: 'certificates',
                    name: 'admin.registries.participants.certificates',
                    component: RegistriesCertificatePrint,
                    props: true,
                  },
                ],
              },
              {
                path: 'calendar',
                component: CommonContainer,
                props: true,
                children: [
                  {
                    path: '',
                    name: 'admin.registries.calendar',
                    component: RegistriesCalendarHome,
                    props: true,
                  },
                  {
                    path: 'create',
                    name: 'admin.registries.calendar.create',
                    component: RegistriesAccessControlCreate,
                    props: true,
                  },
                  {
                    path: ':uuid',
                    component: RegistriesAccessControlContainer,
                    props: true,
                    children: [
                      {
                        path: '',
                        name: 'admin.registries.calendar.edit',
                        component: RegistriesAccessControlEdit,
                        props: true,
                      },
                    ],
                  },
                ],
              },

              {
                path: 'landings',
                name: 'admin.registries.show.landings',
                component: RegistriesShowLandings,
                props: true,
              },
              {
                path: 'forms',
                name: 'admin.registries.show.forms',
                component: RegistriesShowRegisters,
                props: true,
              },
              {
                path: 'thank-yous',
                name: 'admin.registries.show.thank-yous',
                component: RegistriesShowThankyous,
                props: true,
              },

              {
                path: 'login-screens',
                name: 'admin.registries.show.login-screens',
                component: RegistriesShowLoginScreens,
                props: true,
              },
              {
                path: 'confirm-screens',
                name: 'admin.registries.show.confirm-screens',
                component: RegistriesShowConfirmScreens,
                props: true,
              },
              {
                path: 'emails',
                name: 'admin.registries.show.emails',
                component: RegistriesShowEmails,
                props: true,
              },
              {
                path: 'general',
                name: 'admin.registries.general',
                component: RegistriesConfigsIndex,
                props: true,
              },
              {
                path: 'event-broadcasts',
                component: CommonContainer,
                children: [
                  {
                    path: 'rewrite',
                    name: 'admin.registries.event-broadcasts.rewrite',
                    redirect: {
                      name: 'admin.registries.event-broadcasts.index',
                    },
                  },
                  {
                    path: '',
                    name: 'admin.registries.event-broadcasts.index',
                    // component: ConfigsEventBroadcastsIndex,
                    props: true,
                    component: () =>
                      import(
                        /* webpackChunkName: "admin.registries.event-broadcasts.index" */
                        '@/views/Admin/Registries/RegistriesEventBroadcastsIndex.vue'
                      ),
                  },
                  {
                    path: 'create',
                    name: 'admin.registries.event-broadcasts.create',
                    // component: ConfigsEventBroadcastsCreate,
                    props: true,
                    component: () =>
                      import(
                        /* webpackChunkName: "admin.registries.event-broadcasts.create" */
                        '@/views/Admin/Registries/RegistriesEventBroadcastsCreate.vue'
                      ),
                  },
                  {
                    path: ':broadcast',
                    // component: ConfigsEventBroadcastsContainer,
                    component: () =>
                      import(
                        /* webpackChunkName: "admin.registries.event-broadcasts.container" */
                        '@/views/Admin/Registries/RegistriesEventBroadcastsContainer.vue'
                      ),
                    props: true,
                    children: [
                      {
                        path: '',
                        name: 'admin.registries.event-broadcasts.edit',
                        // component: ConfigsEventBroadcastsEdit,
                        component: () =>
                          import(
                            /* webpackChunkName: "admin.registries.event-broadcasts.edit" */
                            '@/views/Admin/Registries/RegistriesEventBroadcastsEdit.vue'
                          ),
                        props: true,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'leaderboards',
                component: CommonContainer,
                children: [
                  {
                    path: '',
                    name: 'admin.registries.leaderboards.index',
                    // component: ConfigsLeaderboardIndex,
                    component: () =>
                      import(
                        /* webpackChunkName: "admin.registries.leaderboards.index" */
                        '@/views/Admin/Registries/RegistriesLeaderboardsIndex.vue'
                      ),
                  },

                  {
                    path: 'rules',
                    component: CommonContainer,
                    children: [
                      {
                        path: '',
                        name: 'admin.registries.leaderboards.rules',
                        component: () =>
                          import(
                            /* webpackChunkName: "admin.registries.leaderboards.rules" */
                            '@/views/Admin/Registries/RegistriesLeaderboardsRules.vue'
                          ),
                        props: true,
                      },
                    ],
                  },
                  {
                    path: 'create',
                    component: CommonContainer,
                    children: [
                      {
                        path: '',
                        name: 'admin.registries.leaderboards.create',
                        component: () =>
                          import(
                            /* webpackChunkName: "admin.registries.leaderboards.create" */
                            '@/views/Admin/Registries/RegistriesLeaderboardsCreate.vue'
                          ),
                        props: true,
                      },
                    ],
                  },
                  {
                    path: 'users/:userId',
                    component: CommonContainer,
                    props: true,
                    children: [
                      {
                        path: '',
                        name: 'admin.registries.leaderboards.user.show',
                        component: () =>
                          import(
                            /* webpackChunkName: "admin.registries.leaderboards.show" */
                            '@/views/Admin/Registries/RegistriesLeaderboardsUsersShow.vue'
                          ),
                        props: true,
                      },
                    ],
                  },
                  {
                    path: ':leaderboard',
                    component: () =>
                      import(
                        /* webpackChunkName: "admin.registries.leaderboards.container" */
                        '@/views/Admin/Registries/RegistriesLeaderboardsContainer.vue'
                      ),
                    props: true,
                    children: [
                      {
                        path: '',
                        name: 'admin.registries.leaderboards.show',
                        component: () =>
                          import(
                            /* webpackChunkName: "admin.registries.leaderboards.show" */
                            '@/views/Admin/Registries/RegistriesLeaderboardsShow.vue'
                          ),
                        props: true,
                      },
                      {
                        path: 'edit',
                        name: 'admin.registries.leaderboards.edit',
                        component: () =>
                          import(
                            /* webpackChunkName: "admin.registries.leaderboards.edit" */
                            '@/views/Admin/Registries/RegistriesLeaderboardsEdit.vue'
                          ),
                        props: true,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'photo-booth',
                component: CommonContainer,
                children: [
                  {
                    path: '',
                    name: 'admin.registries.photo-booth.index',
                    component: () =>
                      import(
                        /* webpackChunkName: "admin.registries.photo-booth.index" */
                        '@/views/Admin/Registries/RegistriesShowPhotoBooth.vue'
                      ),
                  },
                  {
                    path: 'configs',
                    name: 'admin.registries.photo-booth.config.index',
                    component: () =>
                      import(
                        /* webpackChunkName: "admin.registries.photo-booth.config.index" */
                        '@/views/Admin/Registries/RegistriesShowPhotoBoothConfigIndex.vue'
                      ),
                  },
                ],
              },
              {
                path: 'navs',
                component: CommonContainer,
                props: true,
                children: [
                  {
                    path: ':lang',
                    name: 'admin.registries.navs.lang',
                    // component: ConfigsNavItemsIndex,
                    component: () =>
                      import(
                        /* webpackChunkName: "admin.registries.navs-items.index" */
                        '@/views/Admin/Registries/RegistriesNavsIndex.vue'
                      ),
                    props: true,
                  },
                ],
              },
              {
                path: 'classes',
                name: 'admin.registries.tickets',
                component: RegistriesTicketIndex,
                props: true,
              },
              {
                path: 'certificates',
                name: 'admin.registries.certificates',
                component: RegistriesCertificatesIndex,
                props: true,
              },
              {
                path: 'credentials',
                name: 'admin.registries.credentials',
                component: RegistriesCredentialsIndex,
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: 'emails',
        component: CommonContainer,
        children: [
          {
            path: '',
            name: 'admin.emails.index',
            component: () =>
              import(
                /* webpackChunkName: "admin.emails.index" */
                '@/views/Admin/Emails/EmailsIndex.vue'
              ),
          },
          {
            path: 'create',
            component: CommonContainer,
            children: [
              {
                path: '',
                name: 'admin.emails.create',
                component: () =>
                  import(
                    /* webpackChunkName: "admin.emails.create" */
                    '@/views/Admin/Emails/EmailsCreate.vue'
                  ),
                props: true,
              },
            ],
          },
          {
            path: ':id',
            component: () =>
              import(
                /* webpackChunkName: "admin.emails.container" */
                '@/views/Admin/Emails/EmailsContainer.vue'
              ),
            props: true,
            children: [
              {
                path: '',
                name: 'admin.emails.show',
                component: () =>
                  import(
                    /* webpackChunkName: "admin.emails.show" */
                    '@/views/Admin/Emails/EmailsShow.vue'
                  ),
                props: true,
              },
              {
                path: 'edit',
                name: 'admin.emails.edit',
                component: () =>
                  import(
                    /* webpackChunkName: "admin.emails.edit" */
                    '@/views/Admin/Emails/EmailsEdit.vue'
                  ),
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: 'settings',
        component: CommonContainer,
        children: [
          {
            path: '',
            name: 'admin.settings.index',
            // component: UsersIndex,
            component: () =>
              import(
                /* webpackChunkName: "admin-settings-index" */
                '@/views/Admin/Users/UsersIndex.vue'
              ),
          },
        ],
      },
      {
        path: 'channels',
        component: CommonContainer,
        children: [
          {
            path: '',
            name: 'admin.channels.index',
            component: () =>
              import(
                /* webpackChunkName: "admin-channels-index" */
                '@/views/Admin/Channels/ChannelsIndex.vue'
              ),
          },
          {
            path: 'rewrite',
            name: 'admin.channels.rewrite',
            redirect: { name: 'admin.channels.index' },
          },
          {
            path: 'create',
            name: 'admin.channels.create',
            component: () =>
              import(
                /* webpackChunkName: "admin-channels-create" */
                '@/views/Admin/Channels/ChannelsCreate.vue'
              ),
          },
          {
            path: ':id',
            component: () =>
              import(
                /* webpackChunkName: "admin.channels.container" */
                '@/views/Admin/Channels/ChannelsContainer.vue'
              ),
            props: true,
            children: [
              {
                path: '',
                name: 'admin.channels.show',
                component: () =>
                  import(
                    /* webpackChunkName: "admin.channels.show" */
                    '@/views/Admin/Channels/ChannelsShow.vue'
                  ),
                props: true,
              },
              {
                path: 'edit',
                name: 'admin.channels.edit',
                component: () =>
                  import(
                    /* webpackChunkName: "admin.channels.edit" */
                    '@/views/Admin/Channels/ChannelsEdit.vue'
                  ),
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: 'threads',
        component: CommonContainer,
        children: [
          {
            path: '',
            name: 'admin.threads.index',
            component: () =>
              import(
                /* webpackChunkName: "admin.threads.index" */
                '@/views/Admin/Threads/ThreadsIndex.vue'
              ),
          },
          {
            path: 'rewrite',
            name: 'admin.threads.rewrite',
            redirect: { name: 'admin.threads.index' },
          },
          {
            path: 'create',
            name: 'admin.threads.create',
            component: () =>
              import(
                /* webpackChunkName: "admin.threads.create" */
                '@/views/Admin/Threads/ThreadsCreate.vue'
              ),
          },
          {
            path: ':id',
            component: () =>
              import(
                /* webpackChunkName: "admin.threads.container" */
                '@/views/Admin/Threads/ThreadsContainer.vue'
              ),
            props: true,
            children: [
              {
                path: '',
                name: 'admin.threads.show',
                component: () =>
                  import(
                    /* webpackChunkName: "admin.threads.show" */
                    '@/views/Admin/Threads/ThreadsShow.vue'
                  ),
                props: true,
              },
              {
                path: 'edit',
                name: 'admin.threads.edit',
                component: () =>
                  import(
                    /* webpackChunkName: "admin.threads.edit" */
                    '@/views/Admin/Threads/ThreadsEdit.vue'
                  ),
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: 'changelog',
        component: CommonContainer,
        children: [
          {
            path: '',
            name: 'admin.changelog.index',
            component: () =>
              import('@/views/Admin/Changelogs/ChangelogsIndex.vue'),
          },
          {
            path: 'rewrite',
            name: 'admin.changelogs.rewrite',
            redirect: { name: 'admin.changelogs.index' },
          },
        ],
      },
    ],
  },
]
