import { ClickableTypeKeys } from '@/enums/ClickableTypeKeys'

export const ClickableBase = ({
  has_clickable = false,
  click_action_type = '',
  external_url_link = '',
  link_location_virtual_room_id = '',

  link_content_element_id = '',
  link_thread_id = '',
  link_channel_id = '',
  photobooth_config_id = '',
  user_id = '',
}) => ({
  has_clickable,
  external_url_link,
  click_action_type,
  link_location_virtual_room_id,

  link_content_element_id,
  link_thread_id,
  link_channel_id,
  photobooth_config_id,
  user_id,
})

export const setClickableFromDB = (clickable_obj) => {
  return ClickableBase({
    has_clickable: true,
    click_action_type:
      Object.keys(ClickableTypeKeys)[clickable_obj.click_action_type_id - 1],
    external_url_link: clickable_obj.external_url_link,
    link_location_virtual_room_id: clickable_obj.link_location_virtual_room_id,
    link_content_element_id: clickable_obj.link_content_element_id,
    link_thread_id: clickable_obj.link_thread_id,
    link_channel_id: clickable_obj.link_channel_id,
    photobooth_config_id: clickable_obj.photobooth_config_id,
    user_id: clickable_obj.user_id,
  })
}
