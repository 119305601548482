<template>
    <v-dialog
        v-model="open"
        persistent
        scrollable
        max-width="600"
        content-class="tw-rounded-3xl tw-w-full"
    >
        <v-card class="tw-relative tw-rounded-3xl tw-border-0 tw-shadow-md">
            <ModalHeader @close-modal="close">
                {{ $t('admin.ticket.items.labels.config') }}
            </ModalHeader>
            <v-card-text class="tw-bg-gray-100 tw-py-6">
                <!-- ------------------- Add or Edit Class ------------------- -->
                <div class="tw-flex tw-items-center tw-p-4 tw-bg-white tw-rounded-lg tw-shadow ">
                    <v-text-field
                        v-model="registry_tickets.ticket_label_key"
                        :label="$t('admin.ticket.items.labels.label')"
                        outlined
                        hide-details="auto" 
                        rounded
                        dense 
                        clearable
                    />
                    <AppBtn type="button" color="secondary" class="tw-ml-2" @click="addTicket">
                        {{ $t('admin.ticket.items.labels.button') }}
                    </AppBtn>
                </div>
                <!-- ------------------- List Class ------------------- -->
                <div class="tw-py-6">
                    <draggable v-model="registry_tickets" tag="div" handle=".handle" class="tw-overflow-hidden tw-bg-white tw-shadow sm:tw-rounded-lg" ghost-class="ghost">
                        <transition-group type="transition" :name="'flip-list'" tag="ul" class="tw-divide-y tw-divide-gray-200 tw-pl-0">
                            <li v-for="(row, index)  in registry_tickets" :key="index">
                                <div class="tw-block hover:tw-bg-gray-50">
                                    <div class="tw-flex tw-items-center tw-px-2 tw-py-1 sm:tw-px-5 tw-justify-between">
                                        <v-text-field 
                                            v-model="row.ticket_label_key" 
                                            class="tw-font-semibold tw-text-sm tw-pr-2" 
                                            solo 
                                            flat 
                                            single-line
                                            label="Label"
                                            hide-details="auto" 
                                        />
                                        <v-text-field 
                                            v-model="row.enterprise_label" 
                                            class="tw-font-semibold tw-text-sm tw-pr-2 tw-placeholder-gray-300 tw-placeholder-opacity-25" 
                                            solo 
                                            flat 
                                            single-line
                                            label="Enterprise"
                                            hide-details="auto" 
                                        />
                                        <v-text-field 
                                            v-if="registry.has_payment"
                                            v-model="row.price_ticket" 
                                            :label="$t('bz2.registry.setting.tickets.price_ticket')" 
                                            class="tw-font-semibold tw-text-sm tw-pr-2" 
                                            solo 
                                            flat 
                                            prefix="$"
                                            single-line 
                                            hide-details="auto"
                                        />
                                        <!-- ------------------- Tip Tap ------------------- -->
                                        <EditorTipTapModal 
                                            v-model="row.ticket_description" 
                                        />
                                        <!-- ------------------- Access Total ------------------- -->
                                        <v-checkbox v-model="row.is_access_total" hide-details  class="tw-m-0 tw-p-0 tw-text-xs tw-text-white bz-access-total">
                                            <template #label>
                                                <AppBtn color="transparent" fab small type="button" >
                                                    <v-icon :color="row.is_access_total ? 'green':''">
                                                        workspace_premium
                                                    </v-icon>
                                                </AppBtn>
                                            </template>
                                        </v-checkbox>
                                        <!-- ------------------- Delete ------------------- -->
                                        <AppBtn color="transparent-danger" icon-center="close" fab small type="button" @click="deleteTicket(row)" />
                                    </div>
                                </div>
                            </li>
                        </transition-group>
                    </draggable>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <AppBtn type="button" color="transparent" @click="close">
                    {{ $t('app.cancel') }}
                </AppBtn>
                <AppBtn type="button" color="primary" icon="check" @click="save">
                    {{ $t('app.save') }}
                </AppBtn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ModalHeader from '@/components/ModalHeader.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import { mapState } from 'vuex'
import { RegistryTicketData, RegistryTicketHydrateData } from '@/models/RegistryTicket'
import draggable from 'vuedraggable'
import EditorTipTapModal from '@/components/EditorTipTapModal.vue'

export default {
    components: {
        ModalHeader,
        AppBtn,
        draggable,
        EditorTipTapModal,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        registry_tickets: [],
        ticket_desc_modal: false,
    }),
    computed: {
        open: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
        ...mapState({
            registry: (state) => state.registries.show.data,
        }),
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.registry_tickets = this.registry.registry_tickets
                .map((registry_ticket) => {
                    return RegistryTicketHydrateData({
                        ...registry_ticket,
                    })
                })
        },
        save() {
            this.$store.dispatch('registries/updateTicket', {
                payload: {
                    registry_tickets: this.registry_tickets,
                },
            })
            .then(() => 
                this.$store.dispatch('registries/show', {
                    id: this.registry.id,
                }),
            )
        },
        addTicket() {
            if (this.registry_tickets.ticket_label_key) {
                if (this.registry_tickets.map(row => row.ticket_label_key).includes(this.capitalize(this.registry_tickets.ticket_label_key))) {
                    return this.$store.dispatch('notifications/addNotification', {
                        title: 'Mensaje',
                        body: '¡Clase ya existente!',
                        status: 'error',
                    })
                } else {
                    return this.registry_tickets = [
                            ...this.registry_tickets,
                            RegistryTicketData({
                                ticket_label_key: this.capitalize(this.registry_tickets.ticket_label_key),
                                registry_id: this.registry.id,
                            }),
                        ]
                }
            }
        },
        deleteTicket(row) {
            this.registry_tickets = this.registry_tickets.filter((n) => n.ticket_label_key !== row.ticket_label_key)
        },
        capitalize(text) {
            return text[0].toUpperCase() + text.slice(1)
        },
        close() {
          this.open = false
        },
    },
}
</script>
<style>
.bz-access-total .v-input--selection-controls__input {
    display: none;
}
</style>