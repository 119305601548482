<template>
<div class="tw-bg-landing-primary lg:tw-relative">
    <div class="tw-mx-auto tw-max-w-7xl tw-py-24 tw-px-6 lg:tw-px-0">
        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
            <a v-for="mbtn in section.data.buttons" :key="mbtn.uid" class="tw-aspect-w-9 tw-aspect-h-5 tw-block tw-overflow-hidden tw-rounded-xl focus:tw-outline-none" :href="mbtn.has_custom_link ? mbtn.mbutton_url : mbtn.mbutton_pdf" target="_blank">
                <div class="tw-aspect-w-9 tw-aspect-h-5">
                    <img class="tw-object-cover lg:tw-h-full lg:tw-w-full" :src="mbtn.mbutton_image_url" :alt="mbtn.uid" />
                </div>
            </a>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'BizzDomeUiRegistryMultiButtons',
    props: {
        section: {
            type: Object,
            default: () => ({}),
        },
    },
}
</script>
