<template>
<div class="tw-mx-auto tw-max-w-7xl tw-px-4 tw-pt-16 tw-pb-12 sm:tw-px-0 sm:tw-pt-24 lg:tw-gap-8 lg:tw-pb-24 lg:tw-pt-32">
    <div class="lg:tw-grid lg:tw-grid-cols-2 lg:tw-gap-8">
        <!-- Texto -->
        <div class="bz-homehero-text sm:tw-text-center md:tw-mx-auto md:tw-max-w-2xl lg:tw-text-left">
            <h1 v-if="section.data.title">
                <span class="tw-mt-1 tw-block tw-text-4xl tw-font-extrabold tw-tracking-tight sm:tw-text-5xl xl:tw-text-6xl">
                    <span class="bz-hh-title tw-block tw-text-gray-900">{{
            section.data.title
          }}</span>
                </span>
            </h1>
            <p class="bz-hh-text tw-prose tw-mt-3 tw-text-base tw-text-gray-500 sm:tw-mt-5 sm:tw-text-xl lg:tw-text-lg xl:tw-text-xl" v-html="section.data.body" />
            <div class="tw-mt-8 sm:tw-mx-auto sm:tw-max-w-lg sm:tw-text-center lg:tw-mx-0 lg:tw-text-left" />
        </div>
        <!-- Media -->
        <div class="bz-homehero-media tw-relative tw-mt-12 sm:tw-mx-auto sm:tw-max-w-lg lg:tw-mx-0 lg:tw-mt-0 lg:tw-flex lg:tw-max-w-none lg:tw-items-center">
            <div class="tw-relative tw-mx-auto tw-w-full tw-rounded-lg tw-shadow-lg">
                <div class="tw-relative tw-block tw-w-full tw-overflow-hidden tw-rounded-lg tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-500 focus:tw-ring-offset-2">
                    <img v-if="section.data.type_of_media == 'image'" class="tw-aspect-w-16 tw-aspect-h-9" :src="section.data.image_url" alt="" />
                    <template v-else>
                        <vimeo-player v-if="section.data.type_of_media == 'vimeo'" ref="player" :video-url="section.data.video_url" :options="{ autoplay: true }" />
                        <LazyYoutube v-if="section.data.type_of_media == 'youtube'" ref="youtube" :src="section.data.video_url" aspect-ratio="16:9" :autoplay="true" />
                    </template>
                </div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        section: {
            type: Object,
            default: () => ({}),
        },
    },
}
</script>

<style></style>
