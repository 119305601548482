import $axios from '@/axios-instance.js'

export default class RegistryService {
  baseUrl = '/api/v1/registry'

  // Create
  // store (params) {
  //   return $axios.post(this.baseUrl , params)
  // }

  // Read all - findAll
  index(params) {
    return $axios.get(this.baseUrl, params)
  }

  // Read one - show
  show(id, params) {
    return $axios.get(this.baseUrl + `/${id}`, params)
  }

  // Update
  // update (id, params) {
  //   return $axios.put(this.baseUrl + `/${id}`, params)
  // }

  // Delete - delete
  // destroy (id,params) {
  //   return $axios.delete(this.baseUrl + `/${id}`, params)
  // }
}
export const RegistryServiceInstance = new RegistryService()
