import Vue from 'vue'
import Vuex from 'vuex'

import { Notifications } from './modules/notifications'
import { Constants } from './modules/constants'
import { Session } from './modules/session'
import { VirtualRooms } from './modules/virtualRooms'
import { MediaElements } from './modules/mediaElements'
import { mutations } from './mutations'
import { actions } from './actions'
import { EventConfig } from './modules/eventConfig'
import { Registries } from './modules/registries'
import { Registry } from './modules/registry'
import { Event } from './modules/event'
import { Chat } from './modules/chat'
import { Users } from './modules/users'
import { Channels } from './modules/channels'
import { ContentLibraries } from './modules/contentLibraries'
import { Threads } from './modules/threads'
import { Language } from './modules/language'
import { General } from './modules/general'
import { Emails } from './modules/emails'
import { Metrics } from './modules/metrics'
import { License } from './modules/license'
import { Leaderboards } from './modules/leaderboards'
import { ScheduledEmails } from './modules/scheduledEmails'
import { Password } from './modules/password'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tenant: '',
    currentUser: {},
    full_screen_layout: true,
    fullScreenForce: false,
    drawer: false,
    leftDrawer: true,
    logoutModal: false,
    isLoading: false,
    appIsLoading: false,
    appLoaded: false,
    appReady: false,
  },
  mutations: mutations,
  actions: actions,
  modules: {
    users: Users,
    registries: Registries,
    event_config: EventConfig,
    notifications: Notifications,
    constants: Constants,
    session: Session,
    virtual_rooms: VirtualRooms,
    media_elements: MediaElements,
    registry: Registry,
    event: Event,
    chat: Chat,
    content_libraries: ContentLibraries,
    channels: Channels,
    language: Language,
    threads: Threads,
    general: General,
    emails: Emails,
    metrics: Metrics,
    license: License,
    leaderboards: Leaderboards,
    scheduled_emails: ScheduledEmails,
    password: Password,
  },
})
