<template>
  <header class="tw-sticky tw-top-0 tw-z-10 tw-bg-gray-100 tw-shadow">
    <div class="tw-py-2 bz-landing_nav">
      <nav
        class="tw-relative tw-mx-auto tw-flex tw-max-w-7xl tw-items-center tw-justify-between tw-px-4 sm:tw-px-6"
        aria-label="Global"
      >
        <div class="tw-flex tw-flex-1 tw-items-center">
          <div
            class="tw-flex tw-w-full tw-items-center tw-justify-between md:tw-w-auto"
          >
            <router-link :to="toHome">
              <span class="tw-sr-only">Bizz Dome</span>
              <img class="tw-h-10 tw-w-auto sm:tw-h-12 bz-landing_logo" :src="logo" alt="" />
            </router-link>
            <div class="tw--mr-2 tw-flex tw-items-center md:tw-hidden">
              <button
                v-ripple
                type="button"
                class="focus-ring-inset tw-inline-flex tw-items-center tw-justify-center tw-rounded-3xl tw-bg-gray-50 tw-p-2 tw-text-gray-400 hover:tw-bg-gray-100 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-white"
                aria-expanded="false"
                @click="mobile_menu = true"
              >
                <span class="tw-sr-only">Open main menu</span>
                <!-- Heroicon name: outline/menu -->
                <svg
                  class="tw-h-6 tw-w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="tw-hidden md:tw-flex md:tw-items-center md:tw-space-x-6">
          <LocaleSwitcher v-if="canSwitch" />
          <v-btn
            rounded
            text
            class="tw-bg-transparent tw-px-8 tw-font-bold tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-50 bz-landing-btn_login"
            :to="toLogin"
          >
            {{ $t('app.login') }}
          </v-btn>
          <v-btn
            v-if="canRegister"
            rounded
            class="tw-px-8 tw-font-bold tw-normal-case tw-shadow-md focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300 tw-bg-white bz-landing-btn_register"
            :to="toRegister"
          >
            {{ $t('app.register_here') }}
          </v-btn>

          <!-- <a
            href="#"
            class="
              tw-text-base tw-font-medium tw-text-white
              hover:tw-text-gray-300
            "
          >
            Log in
          </a>
          <a
            href="#"
            class="
              tw-inline-flex
              tw-items-center
              tw-px-4
              tw-py-2
              tw-text-base
              tw-font-medium
              tw-text-white
              tw-bg-gray-600
              tw-border
              tw-border-transparent
              tw-rounded-md
              hover:tw-bg-gray-700
            "
          >
            Start free trial
          </a> -->
        </div>
        <transition
          mode="in-out"
          enter-active-class="tw-duration-200 tw-ease-out"
          enter-class="tw-scale-95 tw-opacity-0"
          enter-to-class="tw-scale-100 tw-opacity-100"
          leave-active-class="tw-duration-100 tw-ease-in"
          leave-class="tw-scale-100 tw-opacity-100"
          leave-to-class="tw-scale-95 tw-opacity-0"
        >
          <div
            v-if="mobile_menu"
            class="tw-absolute tw-inset-x-0 tw-top-0 tw-z-30 tw-origin-top-right tw-transform tw-p-2 tw-transition md:tw-hidden"
          >
            <div
              v-click-outside="onlyClose"
              class="tw-divide-y-2 tw-divide-gray-50 tw-rounded-lg tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5"
            >
              <div class="tw-px-5 tw-pt-5 tw-pb-6">
                <div class="tw-flex tw-items-center tw-justify-between">
                  <div>
                    <img class="tw-h-8 tw-w-auto" :src="logo" alt="Bizz Dome" />
                  </div>
                  <div class="tw--mr-2">
                    <button
                      v-ripple
                      type="button"
                      class="tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-bg-white tw-p-2 tw-text-gray-400 hover:tw-bg-gray-100 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-500"
                      @click="mobile_menu = false"
                    >
                      <span class="tw-sr-only">Close menu</span>
                      <!-- Heroicon name: outline/x -->
                      <svg
                        class="tw-h-6 tw-w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="tw-px-5 tw-py-6">
                <div>
                  <v-btn
                    v-if="canRegister"
                    rounded
                    class="tw-w-full tw-bg-white tw-px-8 tw-font-bold tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-50 sm:tw-w-auto bz-landing-btn_register"
                    :to="toRegister"
                  >
                    {{ $t('app.register_here') }}
                  </v-btn>
                  <p
                    class="tw-mt-6 tw-text-center tw-text-base tw-font-medium tw-text-gray-500"
                  >
                    {{ $t('app.already_registered') }}
                    <router-link :to="toLogin" class="tw-text-gray-900">
                      {{ $t('app.login') }}
                    </router-link>
                  </p>
                </div>
              </div>
              <div
                v-if="canSwitch"
                class="tw-flex tw-justify-end tw-px-5 tw-py-6"
              >
                <div>
                  <LocaleSwitcher />
                </div>
              </div>
            </div>
          </div>
        </transition>
      </nav>
    </div>
  </header>
</template>

<script>
import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
import { mapState } from 'vuex'
export default {
  components: {
    LocaleSwitcher,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    mobile_menu: false,
  }),
  computed: {
    canRegister() {
      return !this.registry.is_registration_disabled
    },
    canSwitch() {
      return !this.registry.is_only_active_for_default_lang
    },
    logo() {
      return this.current_landing.logo_url_sm
        ? this.current_landing.logo_url_sm
        : require('@/assets/logo-bizzdome.svg')
    },
    current_landing() {
      return this.registry.landings.find(
        (row) => row.language.name === this.current_language_key,
      )
    },
    ...mapState({
      current_language_key: (state) => state.registry.show.current_language_key,
      registry: (state) => state.registry.show.data,
    }),
    toRegister() {
      return {
        name: 'registry.register',
        params: { slug: this.slug },
      }
    },
    toHome() {
      return {
        name: 'registry.home',
        params: { slug: this.slug },
      }
    },
    toLogin() {
      return {
        name: 'registry.login',
        params: { slug: this.slug },
      }
    },
  },
  methods: {
    onlyClose() {
      this.mobile_menu = this.mobile_menu ? false : true
    },
  },
}
</script>

<style></style>
